import _isObject from 'lodash/isObject';
import * as Sentry from '@sentry/react'
import {
  DATA_TYPES,
  BOOLEAN_OPS,
  IDENTITY_OPS,
  ARRAY_OPS,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums'
import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import getArithmaticParser from 'src/app/slicedForm/ExpressionForm/arithmatic/ArithmeticParser';
import {
  parse
} from 'date-fns';
import { ArithmaticPermissionsError } from '../ExpressionForm/arithmatic/grammar';



const missingColumns = new Set();
const missingColumnDefs = new Set();


const logMissingColumnToSentry = (column) => {
  console.log('MISSING COL VALUE', column);
  if (column && typeof column === 'string') {
    if (!missingColumns.has(column)) {
      missingColumns.add(column);
      Sentry.captureException(Error(`Missing news websocket filter column ${column}`))
    }
  }
}


const logMissingColumnDefToSentry = (column) => {
  console.log('MISSING COL DEF', column);
  if (column && typeof column === 'string') {
    if (!missingColumnDefs.has(column)) {
      missingColumnDefs.add(column);
      Sentry.captureException(Error(`Missing news websocket filter column def ${column}`))
    }
  }
}


// bad solution. When zero_divison, we want to return false, not defaultWhenMissingData
const INVALID_FLAG = 'INVALID_FLAG';


/**
 * Apply our filters directly to records, like we're pretending to be Postgres.
 * NOTE: MathExpressions are unhandled. Maybe with Ohm.js we'll get there. Or,
 * maybe we ignore expressions on News.
 */
class QueryEvaluator {
  validators = {
    'same_type': (a, b) => (a === null || b === null) || (typeof a === typeof b),
    'array': (a, b) => Array.isArray(b),
    'between': (a, b) => Array.isArray(b) && b.length === 2,
  }

  operations = {
    [BOOLEAN_OPS.GE]: {
      func: (a, b) => a >= b,
      validator: this.validators.same_type
    },
    [BOOLEAN_OPS.LE]: {
      func: (a, b) => a <= b,
      validator: this.validators.same_type
    },
    [BOOLEAN_OPS.GT]: {
      func: (a, b) => a > b,
      validator: this.validators.same_type
    },
    [BOOLEAN_OPS.LT]: {
      func: (a, b) => a < b,
      validator: this.validators.same_type
    },
    [BOOLEAN_OPS.EQ]: {
      func: (a, b) => a === b,
      validator: this.validators.same_type
    },
    [BOOLEAN_OPS.BTW]: {
      func: (a, b) => a >= b[0] && a <= b[1],
      validator: this.validators.between
    },
    [IDENTITY_OPS.IS]: {
      func: (a, b) => a === b,
      validator: this.validators.same_type
    },
    [IDENTITY_OPS.IS_NOT]: {
      func: (a, b) => a !== b,
      validator: this.validators.same_type
    },
    [ARRAY_OPS.IN]: {
      func: (a, b) => b.includes(a),
      validator: this.validators.array
    },
    [ARRAY_OPS.NIN]: {
      func: (a, b) => !b.includes(a),
      validator: this.validators.array
    },
  }

  /**
   * @param {Array<Object>} columnDefs - The column definitions. Only used for data-type coercion.
   * @param {object} options - The options object to configure the evaluator.
   * @param {boolean} options.defaultWhenMissingData - If a column is missing, should the clause evaluate True or False?
   * @param {boolean} options.allowExpressions - If we should allow expression strings to be evaluated
   **/
  constructor(
    columnDefs,
    {
      defaultWhenMissingData = true,
      allowExpressions = false
    } = {}
  ) {
    this.data = null;
    this.columnDefs = columnDefs;
    this.defaultWhenMissingData = defaultWhenMissingData;

    this.arithmaticExpressionEvaluator = allowExpressions
      ? getArithmaticParser().evaluate
      : false;

    if (!Array.isArray(this.columnDefs) || !this.columnDefs.length) {
      throw new Error('columnDefs must be an array');
    }
  }


  transformDtype(val, colDef) {
    if (val === null) return null;
    const referenceDate = new Date();
    try {
      if (colDef.dtype === DATA_TYPES.DATE) {
        return parse(val, 'yyyy-MM-dd', referenceDate).getTime();
      }
      if (colDef.dtype === DATA_TYPES.TIME) {
        return parse(val, 'HH:mm:ss', referenceDate).getTime();
      }
      if (colDef.dtype === DATA_TYPES.DATETIME) {
        return parse(val, 'yyyy-MM-dd HH:mm:ss', referenceDate).getTime();
      }
    } catch (err) {
      console.error(err);
      console.log('transformDtype', val, colDef?.dtype);
      return null;
    }

    return val;
  }



  /**
   * Make the replacement if necissary.
   * Returns undefined for missing data. Note, that is not the same as Null.
   * Null is a perfectly valid value.
   */
  getNodeValue(node, data, colDef) {
    let val;
    if (!_isObject(node)) {
      throw new Error('node must be an object');
    }
    if ('column' in node) {
      if (!(node.column in data)) {
        logMissingColumnToSentry(node.column);
        return undefined;
      }
      val = data[node.column];
    }
    else if ('value' in node) {
      val = node.value;
    }
    else if ('expression' in node) {
      if (!this.arithmaticExpressionEvaluator) {
        return undefined
      }
      try {
        val = this.arithmaticExpressionEvaluator(node, data);
      } catch (err) {
        if (err && err?.zero_division) {
          return INVALID_FLAG
        }
        console.warn(`Error evaluating expression ${node?.expression}, ${node?.args}: ${err}`)
        return undefined;
      }
    }
    else {
      throw Error('Left must have either column or value');
    }

    val = this.transformDtype(val, colDef);

    return val;
  }



  /**
   * Recursive. Collects its child evaluations.
   * Replaces { 'column': 'name' } with the literal value inside 'data' param.
   * If data is missing, then return 'defaultWhenMissingData'
   * 
   * @param {Object} rules - Our filter structure
   * @param {Object} data - Data to pull values from, and replace column names
   * @returns {bool} - The evaluation of this particular clause
   */
  apply(rules, data) {
    if (!_isObject(rules)) {
      throw new Error('rules must be an object');
    }
    if (STRUCTURAL_TYPES.SLICE_GROUP in rules) {
      throw new Error('SLICE_GROUP not valid for a QueryStructure filter set');
    }

    // evaluate groups
    if (STRUCTURAL_TYPES.AND in rules) {
      const result = rules[STRUCTURAL_TYPES.AND].every(rule => this.apply(rule, data));
      return result;
    }
    if (STRUCTURAL_TYPES.OR in rules) {
      const result = rules[STRUCTURAL_TYPES.OR].some(rule => this.apply(rule, data));
      return result;
    }
    if (STRUCTURAL_TYPES.CONDITIONAL_AGG in rules) {
      throw new Error('CONDITIONAL_AGG is not evaluatable at this time. It is only valid within Historical contexts.');
    }

    // evaluate nodes
    if (!('operator' in rules)) {
      throw new Error('operator not defined');
    }

    const { left, operator, right } = rules;
    let leftval, rightval

    // TODO: left: { expression: '' }, obviously cannot locate column def.
    // Not sure what to do, do we not map?
    const colDef = this.columnDefs.find(x => x.name === (left?.column || left?.label)); // hacky, .label is for expression.name

    if (!colDef) {
      logMissingColumnDefToSentry(left?.column);
      console.warn('Column missing from colDefs! Returning True')
      return this.defaultWhenMissingData;
    }

    leftval = this.getNodeValue(left, data, colDef);
    if (leftval === undefined) {
      return this.defaultWhenMissingData;
    }
    if (leftval === INVALID_FLAG) {
      return false;
    }

    // right side
    if (Array.isArray(right) && operator !== BOOLEAN_OPS.BTW) {
      throw new Error('Right array must have BTW operator');
    }
    if (operator === BOOLEAN_OPS.BTW && !Array.isArray(right)) {
      throw new Error('BTW operator must have an array as right');
    }

    // validate and extract between
    if (Array.isArray(right) && operator === BOOLEAN_OPS.BTW) {
      const [from, to] = right.map(node => this.getNodeValue(node, data, colDef));
      if (from === undefined || to === undefined) {
        return this.defaultWhenMissingData;
      }
      if (from === INVALID_FLAG || to === INVALID_FLAG) {
        return false;
      }
      rightval = [from, to];
    } else {
      rightval = this.getNodeValue(right, data, colDef);
      if (rightval === undefined) {
        return this.defaultWhenMissingData
      }
      if (rightval === INVALID_FLAG) {
        return false;
      }
    }

    // evaluate
    const { validator, func } = this.operations[operator];
    if (!validator(leftval, rightval)) {
      throw new Error(`Failed argument validator for ${operator} [${colDef.name}] left:${leftval}, right:${rightval}`);
    }

    const result = func(leftval, rightval);
    return result;
  }
}



export default QueryEvaluator
