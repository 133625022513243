import edgeUsersApi from 'src/apis/edgeUsersApi';
import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _flow from 'lodash/flow';
import produce, { current, isDraft } from 'immer';
import requestIdleCallback from 'ric-shim';
import { batch } from 'react-redux';
import { getLeaves } from 'react-mosaic-component';
import * as Sentry from '@sentry/react';
import { COMPONENT_TYPES } from 'src/app/TopListsMosaic/layout/components';
import { PREDEF_PREFIX } from '../profileSettings/profileSettingsConfig';
import { isExpired } from 'src/app/TopListsMosaic/TopListScanner/useMosaicTickerExclude';
import {
  createDefaultLayoutTemplate,
  createComponentSchema,
  copyExistingLayoutSchema,
  PROFILE_TYPES,
  LAYOUT_TEMPLATES,
  PROFILE_CONFIG,
  LINK_COLORS,
} from 'src/redux/layout/topListLayoutSchema';
import edgeDataApi from 'src/apis/edgeDataApi';
import _uniqueId from 'lodash/uniqueId';
import { createErrorNotification, createSuccessNotification } from 'src/redux/notifications/notificationActions';
import DataSourceResponseBatcher from 'src/app/components/grid/topListScanner/dataSource/DataSourceResponseBatcher';
import AsyncQueue from 'src/utils/AsyncQueue';
import { validateProfile } from './topListLayoutValidator';

import LayoutQueueLoggingContext from 'src/utils/logging/LayoutQueueLoggingContext';
import profileToQuery from 'src/app/slicedForm/mapping/profileToQuery';
import { EXPR_VERSION, UPDATE_EXPRESSIONS, WRITE_EXPRESSIONS_LOCAL_STORAGE } from '../expressions/globalExpressionReducer';
import { decideProfileNodeType, STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections';
import {
  forEachComponent,
  forEachProfile,
  getRemovedExpressions,
  getRemovedProfileIds,
  removeDeletedExpressionOrderbyReferences,
  removeDeletedProfileIdReferences
} from './deleteGlobalProfileReferences';
import {
  TreeNode,
  argHasSpecificExpression,
  deleteEntity,
  filterOrAggNodeArgContains
} from 'src/app/slicedForm/mapping/profileTreeManipulation';
import { ALLOW_NULL } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import PromiseHelper from 'src/utils/PromiseHelper';
import { PLAN_LEVELS, PROFILE_PERMISSION_SCOPES } from 'src/hooks/useUserPlanPermissions';


export const CREATE_COMPONENT = '@tl-layout/create-component';
export const CREATE_COMPONENT_ZERO_STATE = '@tl-layout/create-component-zero-state';
export const UPDATE_CURRENT_NODE = '@tl-layout/update-current-node';
export const CREATE_LAYOUT = '@tl-layout/create-layout';
export const COPY_LAYOUT = '@tl-layout/copy-layout';
export const RENAME_LAYOUT = '@tl-layout/rename-layout';
export const DELETE_LAYOUT = '@tl-layout/delete-layout';
export const REORDER_LAYOUT_TABS = '@tl-layout/reorder-layouts';
export const ADD_LAYOUT_TAB = '@tl-layout/add-layout-tab';
export const REMOVE_LAYOUT_TAB = '@tl-layout/remove-layout-tab';
export const UPDATE_PROFILE = '@tl-layout/update-profile';
export const UPDATE_LINKED_DATA = '@tl-layout/update-linked-data';
export const UPDATE_COMPONENT_LINK_COLOR = '@tl-layout/update-component-link-color';
export const UPDATE_TICKER_SEARCH_LINK_COLOR = '@tl-layout/update-ticker-search-link-color';
export const UPDATE_COMPONENT = '@tl-layout/update-component';
export const UPDATE_ACTIVE_LAYOUT = '@tl-layout/update-active-layout';
export const SHOW_ADD_COMPONENT_OVERLAY = '@tl-layout/show-add-component-overlay';
export const HIDE_ADD_COMPONENT_OVERLAY = '@tl-layout/hide-add-component-overlay';
export const BEGIN__LOAD_STORAGE = '@tl-layout/begin__load-storage';
export const SUCCESS__LOAD_STORAGE = '@tl-layout/success__load-storage';
export const FAILURE__LOAD_STORAGE = '@tl-layout/failure__load-storage';
export const INITIALIZE_DEFAULT_LAYOUT = '@tl-layout/initialize-default-layout';
export const REQUEST__FETCH_LIST_WATCHLIST_PROFILES = '@tl-layout/request__fetch-watchlist-profiles';
export const INITIALIZE__FETCH_LIST_WATCHLIST_PROFILES = '@tl-layout/initialize__fetch-watchlist-profiles';
export const FETCH_LIST_WATCHLIST_PROFILES = '@tl-layout/fetch-watchlist-profiles';
export const REQUEST__FETCH_WATCHLISTS = '@tl-layout/request__fetch-watchlist';
export const FETCH_WATCHLISTS = '@tl-layout/fetch-watchlist';
export const REQUEST__EDIT_WATCHLIST = '@tl-layout/request__edit-watchlist';
export const EDIT_WATCHLIST = '@tl-layout/edit-watchlist';
export const REQUEST__ADD_WATCHLIST_TICKER = '@tl-layout/request__add-watchlist-ticker';
export const ADD_WATCHLIST_TICKER = '@tl-layout/add-watchlist-ticker';
export const REQUEST__REMOVE_WATCHLIST_TICKER = '@tl-layout/request__remove-watchlist-ticker';
export const REMOVE_WATCHLIST_TICKER = '@tl-layout/remove-watchlist-ticker';
export const REORDER_WATCHLIST_TICKERS = '@tl-layout/reorder-watchlist-tickers';
export const RENAME_WATCHLIST = '@tl-layout/rename-watchlist';
export const DELETE_WATCHLIST = '@tl-layout/delete-watchlist';
export const REQUEST__CREATE_WATCHLIST = '@tl-layout/request__create-watchlist';
export const CREATE_WATCHLIST = '@tl-layout/create-watchlist';
export const STORE_KEY = 'toplist';


/**
 * @module topListLayoutActions
 * Antipattern used. SUPER FAT action creators, endless getState() calls. Immer in the actions? It's wild.
 *
 * We need to store a silent copy of the reduced action's state into the db.
 * As a result, state must be computed in the action creator. This makes reducers themselves
 * completely useless, but the other options don't work. Other options:
 *
* 1) Don't save on-update, save on timer and persist entire layout reducer rather than slices.
 *    - Doesn't work, we need autosaves that happen at the moment of interaction to rectify problems with multiple browser tabs
 *      editing the same layout. The most recently edited tab should be the one persisted, overwriting any edits on other browser
 *      tabs.
 * 2) Just send the action to the db, and allow the backend to compute the next db state
 *    - I mean it does work, but then we're duplicating the exact same update logic we'd have in the reducer into the backend.
 *      Would be horrible to maintain. We can't wait for the DB to return the computed state either, saving must be silent and the
 *      frontend must be fast.
 * 3) Implement manual saves instead of autosaves, and persist entire reducer on manual save.
 *    - Would work great, but we don't want manual saves.
 * 4) Persist entire reducer on every on-update
 *    - We're talking 20-200KB saves happening very frequently in that case. Don't like the performance implications. We're already doing
 *      3-20kb using the current scheme (not ideal), as an individual layout is the smallest we can slice it.
 *
 *  As a result, I've decided to compute state with immer in the action creator, to keep obj references clean as possible.
 *
 *  I could then just dispatch a single UPDATE_FULL_STATE action with the draft for any and all action creators, but I'm going to keep the named actions
 *  around just for debugging purposes, so I can at least see which events were triggered in the logs.
 */

/**
 * Thunk callback
 * @typedef {function(dispatch, getState): void} Thunk
 */

/**
 * Called when a user selects a new component to add.
 * Note, the <ComponentSelectPanel /> is a component itself, and will also be created here.
 *
 * @param {string} layoutId
 * @param {COMPONENT_TYPES} type
 * @param {string} componentId
 * @param {object} overrides - Additional props to be passed down to the newly created component
 * @returns {Thunk}
 */

const persistDatabaseQueue = new AsyncQueue();


/*
  Called when a user selects a new component to add.
  Note, the "ComponentSelectPanel" is a component itself, and will also be created here.
*/
export const createComponent = (layoutId, type, componentId, overrides = {}) => (dispatch, getState) => {
  const schema = createComponentSchema(type, overrides);

  const state = getState()[STORE_KEY];
  const payload = produce(state, draft => {
    if (!state?.layouts?.[layoutId]) return draft;

    draft.layouts[layoutId].components[componentId] = schema;
    draft.ui.showComponentSelectOverlay = false;

    if (schema?.type !== COMPONENT_TYPES.SELECT) {
      draft.incrementer++;
    }
  });

  // Not saving to db here. UPDATE_CURRENT_NODE will fire after, I'll save component info there
  dispatch({ type: CREATE_COMPONENT, payload });
};


/**
 * If the user deletes all their components, we need a special type of component (with no ID) to show to the user.
 *
 * @param {string} layoutId
* @param {keyof COMPONENT_TYPES} type
 * @param {string} componentId
 * @param {object} overrides - Additional props to be passed down to the newly created component
 * @returns {Thunk}
 */
export const createZeroStateComponent = (layoutId, type, componentId, overrides = {}) => (dispatch, getState) => {
  const schema = createComponentSchema(type, overrides);
  const params = {};

  const state = getState()[STORE_KEY];
  const payload = produce(state, draft => {
    if (!state?.layouts?.[layoutId]) return draft;

    draft.layouts[layoutId].components[componentId] = schema;
    draft.ui.showComponentSelectOverlay = false;

    draft.layouts[layoutId].currentNode = componentId;
    params.putLayouts = [layoutId];

    if (schema?.type !== COMPONENT_TYPES.SELECT) {
      draft.incrementer++;
    }
  });

  // Not saving to db here. UPDATE_CURRENT_NODE will fire after, I'll save component info there
  dispatch(persistLayout(payload, params, 'CREATE_COMPONENT_ZERO_STATE'));
  dispatch({ type: CREATE_COMPONENT_ZERO_STATE, payload });
};


/**
 * Updates the Mosaic tree, triggered by Mosaic event handlers.
 * If we detect a leaf has been deleted, we also go and delete that component from our state *
 *
 * @param {string} layoutId
 * @param {object} newNode - The Mosoic node
 * @returns {Thunk}
 */
export const updateCurrentNode = (layoutId, newNode) => (dispatch, getState) => {
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    if (!state?.layouts?.[layoutId]) return draft;

    // overwrite node
    draft.layouts[layoutId].currentNode = newNode;

    // remove any components within old node
    const previousLeaves = getLeaves(state.layouts[layoutId].currentNode);
    const newLeaves = getLeaves(newNode);
    const removedLeaves = previousLeaves.filter(x => !newLeaves.includes(x));
    removedLeaves.forEach(id => {
      delete draft.layouts[layoutId].components[id];
    });

    params.putLayouts = layoutId;

    draft.incrementer++;
  });

  dispatch({ type: UPDATE_CURRENT_NODE, payload });
  dispatch(persistLayout(payload, params, 'UPDATE_CURRENT_NODE'));
};


/**
 * Creates the default mosaic tree and component schema.
 *
 * @param {object} layoutArgs - Options for the newly created layout
 * @param {string} [templateId=null] - The ID of an existing template to copy.
 * @returns {Thunk}
 */
export const createLayout = (layoutArgs, templateId = null) => (dispatch, getState) => {
  let createScemaCallback = createDefaultLayoutTemplate;
  if (templateId) {
    const template = LAYOUT_TEMPLATES.find(t => t.id === templateId);
    if (template) {
      createScemaCallback = template.createSchema;
    }
  }
  const { layout, layoutId } = createScemaCallback(layoutArgs);
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    draft.layouts[layoutId] = layout;
    params.putLayouts = layoutId;

    draft.layoutTabs.push(layoutId);
    params.layoutTabs = true;

    draft.activeLayout = layoutId;
    params.activeLayout = true;

    draft.incrementer++;
  });

  dispatch({ type: CREATE_LAYOUT, payload: payload });
  dispatch(persistLayout(payload, params, 'CREATE_LAYOUT'));
};


/**
 * Copy a layout. Also generates new IDs for each component, to avoid potential collisions.
 *
 * @param {string} oldLayoutId
 * @param {string} newName
 * @returns {Thunk}
 */
export const copyLayout = (oldLayoutId, newName) => (dispatch, getState) => {
  const state = getState()[STORE_KEY];
  const params = {};

  if (!(oldLayoutId in state.layouts)) return;

  const { layout, layoutId } = copyExistingLayoutSchema(state.layouts[oldLayoutId], newName);

  const payload = produce(state, draft => {
    draft.layouts[layoutId] = layout;
    params.putLayouts = layoutId;

    draft.layoutTabs.push(layoutId);
    params.layoutTabs = true;

    if (draft.layoutTabs.length === 1 || !draft.activeLayout) {
      draft.activeLayout = layoutId;
      params.activeLayout = true;
    }

    draft.incrementer++;
  });

  dispatch({ type: COPY_LAYOUT, payload });
  dispatch(persistLayout(payload, params, 'COPY_LAYOUT'));
};


/**
 * Delete layout. Also deletes any components within the layout.
 *
 * @param {string} layoutId
 * @returns {Thunk}
 */
export const deleteLayout = (layoutId) => (dispatch, getState) => {
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    if (!state?.layouts?.[layoutId]) return draft;

    // Remove layout tab
    const layoutTabIdx = state.layoutTabs.findIndex(tabId => tabId === layoutId);
    if (layoutTabIdx !== -1) {
      draft.layoutTabs.splice(layoutTabIdx, 1);
      params.layoutTabs = true;
    }

    // Remove layout
    delete draft.layouts[layoutId];
    params.deleteLayouts = layoutId;

    // Set active layout if necissary
    if (state.activeLayout === layoutId) {
      draft.activeLayout = Object.keys(draft.layouts).length ? Object.keys(draft.layouts)[0] : null;
      params.activeLayout = true;

      if (draft.activeLayout && !draft.layoutTabs.includes(draft.activeLayout)) {
        draft.layoutTabs.push(draft.activeLayout);
      }
    }

    draft.incrementer++;
  });

  dispatch({ type: DELETE_LAYOUT, payload });
  dispatch(persistLayout(payload, params, 'DELETE_LAYOUT'));
};


/**
 * @param {string} layoutId
 * @param {string} newName
 * @returns {Thunk}
 */
export const renameLayout = (layoutId, newName) => (dispatch, getState) => {
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    if (!state?.layouts?.[layoutId]) return draft;

    draft.layouts[layoutId].name = newName;
    params.putLayouts = layoutId;
    draft.incrementer++;
  });

  dispatch({ type: RENAME_LAYOUT, payload });
  dispatch(persistLayout(payload, params, 'RENAME_LAYOUT'));
};


/**
 * @param {string} layoutId
 * @returns {Thunk}
 */
export const setActiveLayout = (layoutId) => dispatch => {
  const payload = { activeLayout: layoutId };
  const params = { activeLayout: true };

  dispatch({ type: UPDATE_ACTIVE_LAYOUT, payload: layoutId });
  dispatch(persistLayout(payload, params, 'UPDATE_ACTIVE_LAYOUT'));
};

/**
 * Adds a tab to the Top Bar for the given layout.
 *
 * @param {string} layoutId
 * @returns {Thunk}
 */
export const addLayoutTab = (layoutId) => (dispatch, getState) => {
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    if (!state.layoutTabs.includes(layoutId)) {
      draft.layoutTabs.push(layoutId);
      params.layoutTabs = true;
      draft.incrementer++;
    }
  });
  dispatch({ type: ADD_LAYOUT_TAB, payload });
  dispatch(persistLayout(payload, params, 'ADD_LAYOUT_TAB'));
};


/**
 * Removes a Top Bar tab for the given layout.
 *
 * @param {string} layoutId
 * @returns {Thunk}
 */
export const removeLayoutTab = (layoutId) => (dispatch, getState) => {
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    const tabIndex = state.layoutTabs.findIndex(t => t === layoutId);
    if (tabIndex === -1) return draft;

    if (state.layoutTabs.length === 1) return draft;

    draft.layoutTabs.splice(tabIndex, 1);
    params.layoutTabs = true;

    if (state.activeLayout === layoutId) {
      draft.activeLayout = Object.keys(draft.layouts).length ? Object.keys(draft.layouts)[0] : null;
      params.activeLayout = true;
    }
    draft.incrementer++;
  });
  dispatch({ type: REMOVE_LAYOUT_TAB, payload });
  dispatch(persistLayout(payload, params, 'REMOVE_LAYOUT_TAB'));
};


/**
 * Reorders all tabs to the order of the provided IDs.
 *
 * @param {Object[]} layoutOrderIds
 * @returns {Thunk}
 */
export const reorderLayouts = (layoutOrderIds) => dispatch => {
  const payload = { layoutTabs: layoutOrderIds };
  const params = { layoutTabs: true };

  dispatch({ type: REORDER_LAYOUT_TABS, payload: layoutOrderIds });
  dispatch(persistLayout(payload, params, 'REORDER_LAYOUT_TABS'));
};


/**
 * Updates the actual content of a component. Different for every COMPONENT_TYPE.
 *
 * @param {string} componentId
 * @param {string} layoutId
 * @param {object} data - The payload
 * @returns {Thunk}
 */
export const updateComponent = (componentId, layoutId, data) => (dispatch, getState) => {
  /* eslint-disable-next-line no-unused-vars */
  const { link, ...rest } = data;

  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    if (componentId in state.layouts[layoutId].components) {
      draft.layouts[layoutId].components[componentId] = {
        ...state.layouts[layoutId].components[componentId],
        ...data
      };
      params.putLayouts = layoutId;
    }

    draft.incrementer++;
  });

  dispatch(persistLayout(payload, params, 'UPDATE_COMPONENT'));
  dispatch({ type: UPDATE_COMPONENT, payload });
};


const clearFalsey = (obj, keys) => {
  keys.forEach(key => {
    if (!obj[key]) {
      delete obj[key];
    }
  });
  return obj;
}


/**
 * Sets the content for the Link color. Link colors have global settings.
 *
 * @param {string} color
 * @param {object} data
 * @param {string} sourceComponentId - The componentId of the component that triggered the updateLinkedData
 * @param {string} dispatchRule - Flags to change dispatch behavior, like IGNORE_SELF
 * @returns {Thunk}
 *
 * @see LINK_COLORS
 */
export const updateLinkedData = (color, data) => (dispatch, getState) => {
  if (color === 'none') {
    console.warn('Dispatching to None link color invalid. Should be sending to component state.')
    return;
  }

  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    if (color !== LINK_COLORS.white.name) {
      draft.links[color] = {
        ...draft.links[color],
        ...data,
      };
      clearFalsey(draft.links[color], ['dispatchRule', 'sourceComponentId'])
    }

    draft.links.white = {
      ...draft.links.white,
      ...data,
    };
    clearFalsey(draft.links[color], ['dispatchRule', 'sourceComponentId'])

    params.links = true;
  });

  dispatch({ type: UPDATE_LINKED_DATA, payload });
  dispatch(persistLayout(payload, params, 'UPDATE_LINKED_DATA'));
};



/**
 * @param {string} componentId
 * @param {string} layoutId
 * @param {string} color
 * @returns {Thunk}
 *
 * @see LINK_COLORS
 */
export const setComponentLinkColor = (componentId, layoutId, color) => (dispatch, getState) => {
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    if (!state?.layouts?.[layoutId]) return draft;

    if (componentId in state.layouts[layoutId].components) {
      draft.layouts[layoutId].components[componentId].link = color;
      params.putLayouts = layoutId;
      draft.incrementer++;
    }
  });

  dispatch({ type: UPDATE_COMPONENT_LINK_COLOR, payload });
  dispatch(persistLayout(payload, params, 'UPDATE_COMPONENT_LINK_COLOR'));
};


/**
 * Sets the link color for just the global Search bar
 *
 * @param {string} newColor
 * @returns {Thunk}
 *
 * @see LINK_COLORS
 */
export const setTickerSearchLinkColor = (newColor) => (dispatch, getState) => {
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    draft.tickerSearchLinkColor = newColor;
    params.tickerSearchLinkColor = true;
    draft.incrementer++;
  });

  dispatch({ type: UPDATE_TICKER_SEARCH_LINK_COLOR, payload });
  dispatch(persistLayout(payload, params, 'UPDATE_TICKER_SEARCH_LINK_COLOR'));
};


const setLinkMeta = (obj, key, value) => {
  if (!obj) return;
  if (!value && value !== 0) {
    delete obj[key];
  } else {
    obj[key] = value;
  }
}





export const updateNewsColumnProfiles = (profileSettings, layoutId, componentId, expressionAction) => {
  const payload = {
    profileSettings,
    componentId,
    layoutId,
    type: PROFILE_CONFIG.NEWS_COLUMNS
  };
  return updateProfile(payload, expressionAction);
};


export const updateScannerColumnProfiles = (profileSettings, layoutId, componentId, expressionAction) => {
  const payload = {
    profileSettings,
    componentId,
    layoutId,
    type: PROFILE_CONFIG.SCANNER_COLUMNS
  };
  return updateProfile(payload, expressionAction);
};


export const updateScannerFilterProfiles = (profileSettings, layoutId, componentId, expressionPayload) => {
  const payload = {
    profileSettings,
    componentId,
    layoutId,
    type: PROFILE_CONFIG.SCANNER_FILTERS
  };
  return updateProfile(payload, expressionPayload);
};


export const updateKeystatsProfiles = (profileSettings, layoutId, componentId) => {
  const payload = {
    profileSettings,
    componentId,
    layoutId,
    type: PROFILE_CONFIG.KEY_STATS
  };
  return updateProfile(payload);
};


export const updateHistoryColumnProfiles = (profileSettings, layoutId, componentId, expressionPayload) => {
  const payload = {
    profileSettings,
    componentId,
    layoutId,
    type: PROFILE_CONFIG.HISTORY_COLUMNS
  };
  return updateProfile(payload, expressionPayload);
};


export const updateHistoryFilterProfiles = (profileSettings, layoutId, componentId, expressionPayload) => {
  const payload = {
    profileSettings,
    componentId,
    layoutId,
    type: PROFILE_CONFIG.HISTORY_FILTERS
  };
  return updateProfile(payload, expressionPayload);
};

export const updateHistoryAggregateProfiles = (profileSettings, layoutId, componentId, expressionPayload) => {
  const payload = {
    profileSettings,
    componentId,
    layoutId,
    type: PROFILE_CONFIG.HISTORY_AGGREGATES
  };
  return updateProfile(payload, expressionPayload);
};



/**
 * Higher order callback for updating a Profile collection. 
 *
 * I absolutely hate how this has turned out. We must check the entire state for deleted references to
 * profiles and expressions. Its just horrible, there has to be a better way.
 *
 * expressionPayload takes the form of { expressions: [], contextKey: 'string' }.
 * We need expressions here, so we can sync localstorage at the right time, and modify Profile expr__
 * references.
 * TODO: This is really ugly
 *
 * @param {Object} param0
 * @param {Object} param0.profileSettings
 * @param {string} param0.layoutId
 * @param {string} param0.componentId
 * @param {ProfileConfigItem} param0.type
 * @returns {Thunk}
 */
const updateProfile = ({ profileSettings, layoutId, componentId, type }, expressionPayload = {}) => (dispatch, getState) => {
  const { idKey, listKey } = type;
  const { activeProfile: activeProfileId, profiles } = profileSettings;

  const {
    [STORE_KEY]: state,
    expressions: expressionState
  } = getState();

  const params = {};

  let hasExpressionsChanged = false;
  let relevantExpressionStateExpressions = expressionState.expressions || [];

  if (expressionPayload?.expressions && !expressionPayload?.contextKey) {
    throw new Error('updateProfile() requires a contextKey when updating expressions! Skipping update.');
  }

  const payload = produce(state, draft => {
    // ----- PROFILES ------ //

    // Set profiles
    // this sucks because profiles is usually frozen, from useReducer. But we must modify.
    // Bad solution because we clone again during persistence.
    draft[listKey] = _cloneDeep(profiles);
    params[listKey] = true;

    // Set activeProfile for component
    draft.layouts[layoutId].components[componentId][idKey] = activeProfileId;
    params.putLayouts = [layoutId];

    if (expressionPayload?.contextKey) {
      // Make sure we're only _isEqualing against the current contextKey
      relevantExpressionStateExpressions = relevantExpressionStateExpressions.filter(e => e.contextKey === expressionPayload.contextKey);
    }

    // If any profile was deleted that another component is referencing, update that component to use default profile.
    const removedProfileIds = getRemovedProfileIds(profiles, state[listKey]);

    // Should we dispatch expressions?
    hasExpressionsChanged = Array.isArray(expressionPayload?.expressions) && !_isEqual(expressionPayload.expressions, relevantExpressionStateExpressions);

    // Mark expressions for update if they've changed in any way. Not just deletions.
    if (hasExpressionsChanged) {
      params.expressions = true;
    }

    // Do we need to delete expressions from other objects?
    const removedExpressionIds = getRemovedExpressions(
      hasExpressionsChanged,
      expressionPayload?.contextKey,
      expressionPayload?.expressions,
      relevantExpressionStateExpressions
    );


    forEachComponent(draft, ({ layoutId, component }) => {
      const hasDeletedProfileId = removeDeletedProfileIdReferences(
        component,
        removedProfileIds,
        type
      );

      const hasDeletedExprId = removeDeletedExpressionOrderbyReferences(
        component,
        removedExpressionIds
      );

      if (hasDeletedProfileId || hasDeletedExprId) {
        params.putLayouts.push(layoutId);
      }
    });


    draft.incrementer++;

    if (!hasExpressionsChanged) {
      // return early from produce(), everything else relates to expressions.
      return;
    }


    // MUTABLY Remove deleted expressions from COLUMN profiles
    forEachProfile(
      draft,
      PROFILE_CONFIG,
      ({ type }) => type === PROFILE_TYPES.COLUMN,
      (prof, { listKey }) => {
        if (!prof?.columns || !Array.isArray(prof?.columns)) return;
        if (prof.predefined) return;

        const filteredColumns = prof.columns.filter(col => !removedExpressionIds.includes(col?.column));

        if (filteredColumns.length >= prof.columns.length) return;

        prof.columns = filteredColumns;
        params[listKey] = true;
        console.debug(`[EXPR_REMOVE] ...REMOVED DEAD EXPR(s) FROM COLUMN PROFILE "${prof.name}<${listKey}>"`)
      }
    )


    // IMMUTABLY Remove deleted expressions from FILTER profiles.
    // Due to the recursive complexity, build a doubly-linked tree to help.
    forEachProfile(
      draft,
      PROFILE_CONFIG,
      ({ type }) => type in [PROFILE_TYPES.FILTER, PROFILE_TYPES.AGGREGATE],
      (prof, { listKey, type }) => {
        const root = type === PROFILE_TYPES.FILTER ? 'filters' : 'aggregates';

        if (!prof?.[root]) return;
        if (prof.predefined) return;

        const tree = TreeNode.fromProfile(prof[root]);

        if (!tree) {
          console.warn('Unable to build tree for profile', current(prof));
          return;
        }

        let hasChanged = false;

        tree.forEach(node => {
          if (filterOrAggNodeArgContains(node, arg => argHasSpecificExpression(arg, removedExpressionIds))) {
            deleteEntity(node)
            console.debug(`[EXPR_REMOVE] ...REMOVED DEAD EXPR FROM FILTER/AGG PROFILE "${prof.name}[${type}]<${listKey}>"`)
            hasChanged = true;
          }
        });

        if (hasChanged) {
          prof[root] = tree.toProfile();
        }
      }
    )
  });


  batch(() => {
    if (hasExpressionsChanged) {
      dispatch({ type: UPDATE_EXPRESSIONS, payload: expressionPayload });
    }
    dispatch({ type: UPDATE_PROFILE, payload });
    dispatch(persistLayout(payload, params, `UPDATE_PROFILE:${listKey}`, expressionPayload)); // TODO: bad argument ordering
  })
};


export const showAddComponentOverlay = () => {
  return { type: SHOW_ADD_COMPONENT_OVERLAY };
};


export const hideAddComponentOverlay = () => {
  return { type: HIDE_ADD_COMPONENT_OVERLAY };
};


/**
 * Catch-all for any initial mappings we need to make to the profile
 * @param {object} state
 * return {object} - newState
 */
const performStatePreInitializationMapping = (state) => {
  return _flow(
    checkScannerTickerExpirationList,
    changeFiltersAllowNullFieldLocation,
    addAllPredefinedProfiles,
  )(state);
};


/**
 * Apply callback to each component type in all layouts
 * @param {object} state
 * @param {keyof: COMPONENT_TYPES} componentType
 * @param {function} callback
 * @return {object} - newState
 */
const modifyComponentState = (state, componentType, callback) => {
  Object.keys(state.layouts).forEach(layoutId => {
    Object.keys(state.layouts[layoutId].components).forEach(componentId => {
      const component = state.layouts[layoutId].components[componentId];
      if (component.type === componentType) {
        state.layouts[layoutId].components[componentId] = callback(component, componentId);
      }
    });
  });
  return state;
}


/**
 * The excludedTicker list on Component state needs to be cleared at 4am every day.
 * useMosaicTickerExclude checks on update, and provides guards for each fetching API, but
 * we'd still rather start out with correct state on initial load, and check all profiles rather than
 * just the active one.

 * @TODO: We want to run this whenever a user changes layouts too.
 * @TODO: This won't be sent to server unless the user updates the layout. Not necissarily a problem, but not ideal either.
 *
 * @param {object} state
 * @returns {object} - newState
 */
const checkScannerTickerExpirationList = (state) => {
  return modifyComponentState(state, COMPONENT_TYPES.SCANNER, (component, componentId) => {
    const {
      excludedTickers = [],
      excludedTickersExpiration = null
    } = component;

    if (excludedTickers && excludedTickers.length && isExpired(excludedTickersExpiration)) {
      console.log(`[performStatePreInitializationMapping] CLEARED TICKER EXPIRATION. ${component.type}:${componentId}`)
      return {
        ...component,
        excludedTickers: [],
        excludedTickersExpiration: null
      };
    }
    return component;
  });
};



/**
 * Helper function to modify filters before loading to redux
 **/
const mutablyChangeFilters = (filters = {}, callback) => {
  const recurse = (node) => {
    const type = decideProfileNodeType(node);
    switch (type) {
      case STRUCTURAL_TYPES.AND:
      case STRUCTURAL_TYPES.OR:
      case STRUCTURAL_TYPES.SLICE_GROUP: {
        node[type].forEach(recurse);
        break;
      }
      case STRUCTURAL_TYPES.FILTER: {
        callback(node);
        break;
      }
      default: {
        console.warn('mutalbyChangeFilters Unknown node type', node);
      }

    }
  }

  recurse(filters);
}


/**
 * Migrate node.left.allowNull to node.allowNull, if necissary.
 * The old way was wrong, and handled clientside. New way is serverside.
 * TODO: Would like to fix this in the databse after deploy.
 *
 * @param {object} state
 * @return {object} newState
 **/
const changeFiltersAllowNullFieldLocation = (state) => {

  const profileParams = Object.values(PROFILE_CONFIG)
    .filter(value => !value.nonLayoutState && value.type === PROFILE_TYPES.FILTER)
    .map(value => value.listKey);

  const doUpdate = (filter) => {
    if (filter && filter?.left && filter?.left?.allowNull) {
      filter.allowNull = filter.left.allowNull;
      console.log('UPDATED ALLOW NULL FOR INCOMMING FILTER', filter);
      delete filter.left.allowNull;
    }

    // somethings broken, we're seeing filter.allowNull === USER_CONTROLLED...
    if (filter && filter?.allowNull) {
      if (filter.allowNull === true) {
        // pass
      }
      else if (filter.allowNull === ALLOW_NULL.USER_CONTROLLED) {
        console.log('MODIFIED ALLOW NULL BROKEN')
        filter.allowNull = true;
      } else if (filter.allowNull === ALLOW_NULL.TRUE) {
        filter.allowNull = true;
      } else {
        delete filter.allowNull;
      }
    }
  }

  for (const profileKey of profileParams) {
    if (profileKey in state && state[profileKey]) {
      state[profileKey].forEach(profile => {
        mutablyChangeFilters(profile?.filters, doUpdate)
      });
    }
  }

  return state;
}


/**
 * Loop through PROFILE_CONFIG, and add the default profiles to the user's profiles.
 * Should be performed on initial load.
 * @param {object} state
 * @return {object} - newState
 */
const addAllPredefinedProfiles = (state) => {
  const profileParams = Object.values(PROFILE_CONFIG)
    .filter(value => !value.nonLayoutState)
    .map(value => value.listKey);

  for (const profileKey of profileParams) {
    if (profileKey in state && state[profileKey]) {
      const profileTypeDefinitionKey = Object.keys(PROFILE_CONFIG).find(p => PROFILE_CONFIG[p].listKey === profileKey);
      if (!profileTypeDefinitionKey) {
        throw Error(`profileListKey not recognized! ${profileKey}`);
      }
      state[profileKey] = [
        ...PROFILE_CONFIG[profileTypeDefinitionKey].predefinedProfiles,
        ...state[profileKey]
      ];
    }
  }
  console.log(state, PROFILE_CONFIG);
  return state;
};


/**
 * Loop through PROFILE_CONFIG, and remove the default profiles from the user's profiles.
 * Should be called on save. We don't want default profiles persisting.
 *
 * @param {Object} state
 * @return {Object} - newState
 */
const stripAllPredefiendProfiles = (state) => {
  const PROFILE_PARAMS = Object.values(PROFILE_CONFIG)
    .filter(value => !value.nonLayoutState)
    .map(value => value.listKey);

  for (const profileKey of PROFILE_PARAMS) {
    if (profileKey in state && state[profileKey]) {
      state[profileKey] = stripLayoutPredefinedProfiles(state[profileKey]);
    }
  }
  return state;
};


/**
 * Remove predefined profiles from the array of all profiles of a given type.
 * imp
 * @param {Object[]} profiles
 * @returns {Object[]}
 */
const stripLayoutPredefinedProfiles = (profiles) => {
  const isPredefined = (profile) => {
    return profile.predefined || profile.id.startsWith(PREDEF_PREFIX);
  }
  return profiles.filter(p => !isPredefined(p));
};


/**
 * Manual instructions for how to deal with Permissions modifications.
 **/
const scopeComponentModificatons = {
  [PROFILE_PERMISSION_SCOPES.disabledCustomization]: (profileItems, componentId, component, scopeValueForPlan, profileConfig) => {
    try {
      // Scope value doesn't require changes
      if (scopeValueForPlan !== true) return;

      // If component has this profile
      if (!component?.[profileConfig.idKey]) return;

      const activeProfile = profileItems.find(p => p.id === component[profileConfig.idKey]);

      // If profile is custom
      if (activeProfile.predefined) return;

      // Change the active profile to the default
      const oldId = component[profileConfig.idKey];
      component[profileConfig.idKey] = profileConfig.defaultProfileId;

      console.log(
        `[LAYOUT:fixProfilePermissions:disabledCustomization:${profileConfig.idKey}] Changed profile to default ${componentId}:${profileConfig.idKey}, ${oldId} => ${profileConfig.defaultProfileId}`
      );
    } catch (err) {
      console.error(`[ERROR: fixProfilePermissions:disabledCustomization:${profileConfig.idKey}], component:${componentId}`, err);
    }
  },
  [PROFILE_PERMISSION_SCOPES.disallowedPredefinedProfiles]: (profileItems, componentId, component, scopeValueForPlan, profileConfig) => {
    try {
      // Scope value doesn't require changes
      if (!Array.isArray(scopeValueForPlan) || !scopeValueForPlan.length) return;

      // If component has this profile
      if (!component?.[profileConfig.idKey]) return;

      // If profile is not in the disallowed list
      if (!scopeValueForPlan.includes(component[profileConfig.idKey])) return;

      // Change the active profile to the default
      const oldId = component[profileConfig.idKey];
      component[profileConfig.idKey] = profileConfig.defaultProfileId;

      console.log(
        `[LAYOUT:fixProfilePermissions:disallowedPredefinedProfiles:${profileConfig.idKey}] Changed profile to default ${componentId}:${profileConfig.idKey}, ${oldId} => ${profileConfig.defaultProfileId}`
      )
    } catch (err) {
      console.error(`[ERROR: fixProfilePermissions:disallowedPredefinedProfiles:${profileConfig.idKey}], component:${componentId}`, err);
    }
  }
}


/**
 * When a user downgrades, their layout state might need to be adjusted.
 * For example, Basic users aren't allowed to use Custom aggregate profiles.
 **/
const fixProfilePermissionsOnDowngrade = (state, planLevel, permissions) => {
  try {
    if (!planLevel || !permissions || !Object.keys(permissions).length) {
      return state;
    }

    forEachComponent(state, ({ componentId, component }) => {
      Object.entries(permissions).forEach(([profileListKey, scopeObject]) => {
        const profileConfig = Object.values(PROFILE_CONFIG).find(p => p.listKey === profileListKey);
        const profileItems = state?.[profileListKey] || [];

        Object.entries(scopeObject).forEach(([scope, scopeValue]) => {
          if (!scope || !scopeValue) return;
          if (!(scope in scopeComponentModificatons)) return;
          if (!scopeValue?.[planLevel]) return;

          scopeComponentModificatons[scope](
            profileItems,
            componentId,
            component,
            scopeValue[planLevel],
            profileConfig
          );
        });
      });
    })
  } catch (err) {
    Sentry.captureEvent(err, {
      place: 'fixProfilePermissionsOnDowngrade',
      planLevel,
      permissions
    });
  }

  return state;
}



/////////////////////////////////////////////////
////// WATCHLIST! Handled very differently //////
/////////////////////////////////////////////////


/*
Strategy:
- NOT USING THE REGULAR LAYOUT LOGIC! The watchlists are stored in RDS, so we're taking a traditional redux approach for this one.
- Fetch all watchlists, in a single combined request. Basically add all columns+tickers together in the backend.
- Don't persist to dynamo or localstorage, except for component-level state (active profile ID). The list of profiles will be fetched from RDS inside the component, on first mount.
- Most updates are silent, except for adding a new ticker (since we need the new realtime data immediatly)
- Actions that modify watchlists+component state need to mix strategies. One part for regular layout stuff, one part for rds/redux.
*/

export const intIdExists = (id) => id || id === 0;

/**
 * Fetch metadata for all user watchlists
 * @return {Thunk}
 */
export const fetchListWatchlists = () => async dispatch => {
  dispatch({ type: REQUEST__FETCH_LIST_WATCHLIST_PROFILES });

  let payload = [];
  try {
    const { data } = await edgeDataApi.get('/watchlists');
    payload = data;
  } catch (err) {
    Sentry.captureException(err);
  }

  dispatch({ type: FETCH_LIST_WATCHLIST_PROFILES, payload });
};


let prevParams = {};
let prevTime = 0;

const watchlistProfileToQuery = (columns, expressions) => {
  const { columns: colOut } = profileToQuery({ columns }, null, null, expressions?.expressions);
  return colOut;
}

/**
 * Fetch data for all visible watchlists in a single go.
 *
 * @param {boolean} [preventFetchIfAlreadyFetching=false]
 * Certain actions take prescedence over others. If modifying for example, don't let the Automated timer
 * fetch override the update fetch
 * @return {Thunk}
 */
export const fetchWatchlists = (preventFetchIfAlreadyFetching = false) => async (dispatch, getState) => {
  const {
    expressions,
    [STORE_KEY]: state
  } = getState();

  if (!state.activeLayout) return;
  if (preventFetchIfAlreadyFetching && state.isFetching.watchlistData) return;

  const activeLayoutId = Object.keys(state.layouts).find(lid => lid === state.activeLayout);

  if (!activeLayoutId) return;

  let watchlistIds = Object.values(state.layouts[activeLayoutId].components)
    .filter(c => c.type === COMPONENT_TYPES.WATCHLIST && intIdExists(c?.[PROFILE_CONFIG.WATCHLIST_ROWS.idKey]))
    .map(c => c[PROFILE_CONFIG.WATCHLIST_ROWS.idKey]);

  watchlistIds = [...new Set(watchlistIds)];

  if (!watchlistIds || !watchlistIds.length) return;

  let watchlistColumnIds = Object.values(state.layouts[activeLayoutId].components)
    .filter(c => c.type === COMPONENT_TYPES.WATCHLIST && intIdExists(c?.[PROFILE_CONFIG.WATCHLIST_ROWS.idKey]))
    .map(c => c[PROFILE_CONFIG.SCANNER_COLUMNS.idKey]);
  watchlistColumnIds = [...new Set(watchlistColumnIds)];

  let watchlistColumns = state[PROFILE_CONFIG.SCANNER_COLUMNS.listKey]
    .filter(c => watchlistColumnIds.includes(c.id))
    .reduce((acc, curr) => [...acc, ...curr.columns], []);

  watchlistColumns = [...new Set(watchlistColumns)];

  const columns = watchlistProfileToQuery(watchlistColumns, expressions);

  // Remove duplicates. Mounting components will send in the same request, often before redux can update.
  // Normally this function is only called once, on timer. Except for mounts, where duplicates happen.
  // This feels like an ugly solution, but really it's pretty robust. Much more simple than managing flags.
  // TODO: I think this deduping is obsolete now, due to TopListMosaic useEffect() timeout fetching. Not 100% sure.

  const params = {
    watchlistIds,
    columns
  };

  let timeSince = (+new Date()) - prevTime;

  if (_isEqual(params, prevParams) && timeSince < 2000) {
    return;
  }

  prevParams = params;
  prevTime = +new Date();

  void DataSourceResponseBatcher.registerRequest({
    id: 'watchlists',
    promise: (async () => {
      dispatch({ type: REQUEST__FETCH_WATCHLISTS });
      try {
        const { data } = await edgeDataApi.post('/watchlists/data', params);
        return data;
      } catch (err) {
        Sentry.captureException(err);
        dispatch({ type: FETCH_WATCHLISTS, payload: [] });
      }
    })(),
    onResolve: (data = []) => {
      if (!data || !Array.isArray(data)) {
        return dispatch({ type: FETCH_WATCHLISTS, payload: [] });
      }
      dispatch({ type: FETCH_WATCHLISTS, payload: data });
    },
  });
};


/**
 * @summary Add ticker to watchlist, and immediatly refresh all watchlist data.
 * Use getState to find any components using this watchlist.id, and condense the columns.
 *
 * @param {string} watchlistProfileId
 * @param {string} ticker
 * @returns {Thunk}
 */
export const addWatchlistTicker = (watchlistProfileId, ticker) => async (dispatch, getState) => {
  if (!ticker) return;

  const {
    expressions,
    [STORE_KEY]: state
  } = getState();

  if (!state.activeLayout) return;

  const loadingItemId = _uniqueId(watchlistProfileId);

  dispatch({ type: REQUEST__ADD_WATCHLIST_TICKER, payload: { watchlistProfileId, ticker, loadingItemId } });

  const activeLayoutId = Object.keys(state.layouts).find(lid => lid === state.activeLayout);
  if (!activeLayoutId) return;

  const components = Object.values(state.layouts[activeLayoutId].components);

  let columns = Object.values(components).reduce((acc, curr) => {
    if (curr.type === COMPONENT_TYPES.WATCHLIST && curr?.[PROFILE_CONFIG.WATCHLIST_ROWS.idKey] === watchlistProfileId) {
      let columnProfile = state[PROFILE_CONFIG.SCANNER_COLUMNS.listKey].find(prof => prof.id === curr[PROFILE_CONFIG.SCANNER_COLUMNS.idKey]);
      if (columnProfile && columnProfile?.columns && columnProfile.columns.length) {
        acc.push(...columnProfile.columns);
      }
    }
    return acc;
  }, []);


  columns = watchlistProfileToQuery([...new Set(columns)], expressions);

  let payload = { watchlistProfileId, loadingItemId };
  try {
    const { data } = await edgeDataApi.put(`/watchlists/${watchlistProfileId}/${ticker}`, { columns });
    payload = {
      ...payload,
      tickerData: data,
      ...createSuccessNotification(`${ticker} added to watchlist`)

    };
  } catch (err) {
    let msg = err?.response?.data?.message || `Failed to add ${ticker} to watchlist`;
    payload = {
      ...payload,
      tickerData: [],
      ...createErrorNotification(msg)
    };
  }
  dispatch({ type: ADD_WATCHLIST_TICKER, payload });
};

/***
 * Remove a ticker from a watchlist. Silently updates the database without verifying.
 *
 * @param {string} watchlistProfileId
 * @param {string} watchlistItemId - The database ID of the watchlist row item
 * @returns {Thunk}
 */
export const removeWatchlistTicker = (watchlistProfileId, watchlistItemId) => async dispatch => {
  if (!intIdExists(watchlistItemId) || !intIdExists(watchlistProfileId)) return;

  dispatch({ type: REQUEST__REMOVE_WATCHLIST_TICKER, payload: { watchlistProfileId, watchlistItemId } });
  let status = false;
  try {
    const { data } = await edgeDataApi.delete(`/watchlists/${watchlistProfileId}/${watchlistItemId}`);
    status = ['true', 'True', 'TRUE', true].includes(data); // LOL
  } catch (err) {
    Sentry.captureException(err);
  }
  dispatch({ type: REMOVE_WATCHLIST_TICKER, payload: { watchlistProfileId, watchlistItemId, status } });
};

/**
 * Reorder watchlist tickers. Silently updates the database.
 *
 * @param {string} watchlistProfileId
 * @param {object} param1
 * @param {string[]} param1.order - The new order of the tickers
 * @returns {Thunk}
 */
export const reorderWatchlistTickers = (watchlistProfileId, { order }) => async dispatch => {
  dispatch({ type: REQUEST__FETCH_WATCHLISTS });
  try {
    await edgeDataApi.patch(`/watchlists/${watchlistProfileId}`, { order });
  } catch (err) {
    Sentry.captureException(err);
  }
  dispatch({ type: REORDER_WATCHLIST_TICKERS, payload: { watchlistProfileId, order } });
};


/**
 * @param {string} watchlistProfileId
 * @param {string} newName
 * @returns {Thunk}
 */
export const renameWatchlist = (watchlistProfileId, newName) => async dispatch => {
  dispatch({ type: RENAME_WATCHLIST, payload: { watchlistProfileId, newName } });
  try {
    await edgeDataApi.patch(`/watchlists/r/${watchlistProfileId}`, { name: newName });
  } catch (err) {
    Sentry.captureException(err);
  }
};

/**
 * @summary Delete a watchlist.
 *
 * This one is confusing, since we need to modify the overal layout (like we do for non-watchlist stuff) as well as the watchlist stuff.
 * This means I'm going to use my produce() action creator to handle the layout stuff, then make an endpoint call for rds.
 *
 * Watchlist keys aren't actually saved.
 * This means updateProfile() will just handle updating profileIds that are referencing deleted watchlists.
 * If I messed this check up, then we'll start seeing watchlist data in dynamo and localstorage, which we shouldn't.
 *
 * @param {string} watchlistProfileId
 * @returns {Thunk}
 */
export const deleteWatchlist = (watchlistProfileId) => async (dispatch, getState) => {
  const { listKey, idKey } = PROFILE_CONFIG.WATCHLIST_ROWS;
  const state = getState()[STORE_KEY];
  const params = {};

  const payload = produce(state, draft => {
    // Delete profile
    const idx = draft[listKey].findIndex(p => p.id === watchlistProfileId);
    if (idx !== -1) {
      draft[listKey].splice(idx, 1);
    }

    // Update any references to deleted profile
    const removedProfileIds = [watchlistProfileId];
    params.putLayouts = [];

    Object.entries(draft.layouts).forEach(([lId, layout]) => {
      Object.entries(layout.components).forEach(([componentId, schema]) => {
        if (idKey in schema) {
          if (removedProfileIds.includes(schema[idKey])) {
            draft.layouts[lId].components[componentId][idKey] = PROFILE_CONFIG.WATCHLIST_ROWS.defaultProfileId;
            params.putLayouts.push(lId);
          }
        }
      });
    });

    draft.incrementer++;
  });

  dispatch({ type: UPDATE_PROFILE, payload });
  dispatch(persistLayout(payload, params, `DELETE_WATCHLIST:${listKey}`));

  try {
    await edgeDataApi.delete(`/watchlists/${watchlistProfileId}`);
  } catch (err) {
    Sentry.captureException(err);
  }
};


/**
 * @summary Create a watchlist. This will not be silent, we will wait for the db to respond.
 * Updates layout state, so we need to use the old strategy.
 *
 * @param {string} componentId
 * @param {string} layoutId
 * @param {string} name
 * @param {string} [copyId=null] - Optional ID of existing watchlist to copy
 * @returns {Thunk}
 */
export const createWatchlist = (componentId, layoutId, name, copyId = null) => async (dispatch, getState) => {
  // dispatch({ type: REQUEST__CREATE_WATCHLIST })

  const state = getState()[STORE_KEY];
  const params = {};

  const component = state?.layouts[layoutId]?.components[componentId];
  if (!component) return;
  const columnProfile = state[PROFILE_CONFIG.SCANNER_COLUMNS.listKey].find(p => p.id === component[PROFILE_CONFIG.SCANNER_COLUMNS.idKey]) || {};
  const columns = columnProfile?.columns || [];

  let response = { profile: {}, data: [] };
  try {
    const { data } = await edgeDataApi.post(`/watchlists`, {
      name,
      columns,
      copyId
    });
    response = data;
  } catch (err) {
    Sentry.captureException(err);
  }

  if (!response?.profile?.id) {
    // Why did I have this? Just sending an empty profile makes no sense...
    // return dispatch({ type: CREATE_WATCHLIST, payload: response });
    return dispatch({ type: CREATE_WATCHLIST, payload: { ...createErrorNotification('Watchlist failed to create') } });
  }

  const payload = produce(state, draft => {
    if (componentId in state.layouts[layoutId].components) {
      // Add profile to list
      draft.layouts[layoutId].components[componentId][PROFILE_CONFIG.WATCHLIST_ROWS.idKey] = response.profile.id;
      params.putLayouts = layoutId;

      const idx = draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey].findIndex(p => p.id === response.profile.id);
      if (idx === -1) {
        draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey].push(response.profile);
      } else {
        draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey][idx] = {
          ...draft[PROFILE_CONFIG.WATCHLIST_ROWS.listKey][idx],
          ...response.profile
        };
      }

      // Insert new rows into data
      if (response?.data.length) {
        response.data.forEach(row => {
          const idx = draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].findIndex(p => p.item_id === row.item_id);
          if (idx !== -1) {
            draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey][idx] = {
              ...draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey][idx],
              ...row
            };
          } else {
            draft[PROFILE_CONFIG.WATCHLIST_ROWS.dataKey].push(row);
          }
        });
      }

      draft.incrementer++;
    }
  });

  dispatch(persistLayout(payload, params, 'UPDATE_COMPONENT'));
  dispatch({ type: UPDATE_COMPONENT, payload });
  // dispatch({ type: CREATE_WATCHLIST, payload: response });
};


/////////////////////////////////////////////////
/////// SAVING / LOADING AND LOCALSTORAGE ///////
/////////////////////////////////////////////////

/** Previously valid versions */
const EXPIRED_VERSIONS = ['v1', 'v1.1', 'v1.3', 'v1.1'];
/** current version */
const STORAGE_VERSION = 'v2';
/** localstorage location */
export const storageKey = `layout#toplist#${STORAGE_VERSION}`;


const namespacedStorageKey = (userSub) => {
  return `${storageKey}#${userSub}`;
}


for (const v of EXPIRED_VERSIONS) {
  try {
    localStorage.removeItem(`layout#toplist#${v}`);
  } catch (err) {
    // empty
  }
}

/**
 buildParams() Accepts the immer produced state, and an object with a bunch of flags that specify which pieces we should pull from
 the state and send to the backend.
 
 Immer works with proxy objects, so setting the params object within the produce function doesn't work once the proxy
 is released. We have to get the values after the proxy is released. I decided to store 'specifiedParams' flags, which tell
 buildParams which pieces of data to retrieve from the final state.
 
 (TODO: Is this crazy? I'm not sure. Maybe investigating proxy objects more could help, but this works for now)
 
 specifiedParams structure
 {
 activeLayout: true
 links: true
 layoutTabs: true
 putLayouts: { layoutId: fullLayoutObj }
 deleteLayouts: [ layoutIds ]
 ...profileKeys{}: true
 }
 
 returns Request structure:
 {
 meta {
 activeLayout: '',
 links: {},
 layoutTabs: []
 }
 layouts: {
 put: { layoutId: {} }
 delete: [layoutId]
 },
 columnProfiles: [],
 filterProfiles: [],
 keyStatsProfiles: [],
 news,
 expressions: { contextKey: [] }
 }
 **/

const SPECIAL_PARAMS = ['putLayouts', 'deleteLayouts'];
const META_PARAMS = ['activeLayout', 'links', 'layoutTabs', 'tickerSearchLinkColor'];

/**
 * Convert the produced Immer state into an API update query
 * @param {object} state
 * @param {object} specifiedParams - The keys we should pull out of 'state' to send to the backend
 * @return {object} - the data to send
 */
const buildLayoutParamsFromReducedState = (state, specifiedParams, expressionPayload) => {
  const PROFILE_PARAMS = Object.values(PROFILE_CONFIG)
    .filter(value => !value.nonLayoutState)
    .map(value => value.listKey);
  const VALID_PARAMS = [...SPECIAL_PARAMS, ...META_PARAMS, ...PROFILE_PARAMS, 'expressions'];

  const params = {};
  Object.keys(specifiedParams).forEach(paramKey => {
    if (!VALID_PARAMS.includes(paramKey)) {
      throw Error(`Invalid param "${paramKey}" specified`);
    }

    if (paramKey === 'putLayouts') {
      let layoutIds = Array.isArray(specifiedParams[paramKey]) ? specifiedParams[paramKey] : [specifiedParams[paramKey]];
      const puts = {};
      layoutIds.forEach(lid => {
        puts[lid] = state.layouts[lid];
      });
      params.layouts = params.layouts || {};
      params.layouts.put = puts;
    } else if (paramKey === 'deleteLayouts') {
      let layoutIds = Array.isArray(specifiedParams[paramKey]) ? specifiedParams[paramKey] : [specifiedParams[paramKey]];
      params.layouts = params.layouts || {};
      params.layouts.delete = layoutIds;
    } else if (META_PARAMS.includes(paramKey)) {
      params.meta = params.meta || {};
      params.meta[paramKey] = state[paramKey];
    } else if (PROFILE_PARAMS.includes(paramKey)) {
      params[paramKey] = stripLayoutPredefinedProfiles(state[paramKey]);
    } else if (paramKey === 'expressions' && specifiedParams[paramKey] === true) {
      // Only a single contextKey will be saved at a time. In the payload.
      const { expressions, contextKey } = expressionPayload;
      if (!expressions || !contextKey) return;

      params[paramKey] = {
        [contextKey]: expressions,
        version: EXPR_VERSION
      };
    } else {
      params[paramKey] = state[paramKey];
    }
  });

  return params;
};


const queueLogger = new LayoutQueueLoggingContext();


/**
 * Tries to save to localstorage and database
 *
 * @param {object} state
 * @param {object} specifiedParams - The keys we should pull out of 'state' to send to the backend
 * @param {string} [reason=''] - Reason for saving
 * @returns {Thunk}
 */
const persistLayout = (state, specifiedParams, reason = '', expressionPayload = {}) => async (dispatch, getState) => {
  /*
 
  -- EXPRESSIONS 7/3/24 -- 
 
  This is horrible. We need to save them in the same request as toplist, but they don't live in the same reducer.
  This pattern cannot stay like this. But for now:
 
  SAVING:
 
  1) on any updateProfile, expressions are sent in
  - If there is a diff with getState(), then we mark them to be saved in params
  - Also clean deleted from columns and orderby. Filters is too messy to clean.
 
  2) Once in params, but before network, we dispatch to globalExpressionReducer.
 
  3) Next, we pass the new expressions into persistLayout(). Its not part of state, new param.
    - gets bundled into main layout request
    - Timestamp added to that peice too, saved in user.expressionTimestamp#version
 
  4) If successful, then we dispatch WRITE_EXPRESSIONS_LOCAL_STORAGE, with the timestamp.
    - expression reducer will mark timestamp in it's state
    - That timestamp will be persisted to localstorage
 
  LOADING:
 
  1) middleware loads expressions from localstorage into expressionReducer.
    - This will have timestamp of last save, done by toplistlayout flow.
 
  2) AppLayout makes a global fetch for expressions, if user.expressionTimestamp#version is newer than localstorage.expressionTimestamp#version
 
  3) If fetched and succesfull, dispatch.
 
  IDEAS FOR FUTURE (EXPRESSIONS)
 
  1) Just move it into the damn toplist. will not work for history through.
 
  2) Just accept 2 network requests, and build UI in such a way that missing expressions do not matter
 
  3) Something more clever than what we have now, that can handle merging network requests.
 
 
  -- LAYOUT FUTURE CONSIDERATIONS --
 
  Having to build two structures (localstorage vs database) is a bad call.
 
  - What about a diff-based approach? We could store the last state, and only send the diff to the database.
    That has the problem of needing full reducer logic in the backend.
 
  - CRDTs?
    This is probably correct, but with significant upkeep.
 
 
 
  -- BAD DB STATE UPDATE #2: Dec 13, 2023 --
  We hadn't considered an angle.
  User disconnects, creates profile, reconnects, updates layout.
 
  In that case, once reconnected the layout will be persisted, but done so without persiting the profile
  that was created offline. This is because we build state from Redux, not from prevState (localstorage).
 
  AsyncQueue solution:
   - Add all POST requests to a queue.
   - queue immediatly invokes all, in order.
   - On error, stop processing and pause the queue.
   - Next update, try the whole queue again, in order.
   - On queue clear, persist to localstorage.
 
  This way, we always send events to the database IN ORDER. Patches are applied properly.
  If the user never reconnects, then we simply lose data, but we do not get into an invalid state.
  If the user reconnects temporarily, and only some of the queue is applied, then thats okay. The patches
    are in order, so the database will be in a valid state. It will also have the most recent timestamp, not localstorage.
 
  Failed solutions;
  1) Persist entire state to database on reconnect.
      Too difficult, backend does not accept full overwrites like this. Would need another endpoint, or to
      build a custom Params object with deletes and puts that are properly rectified
  2) LocalStorage as source-of-truth. Way to big a change.
 
 
  -- BAD DB STATE UPDATE #1: Oct 10, 2023 --
  If the databse call fails, don't attempt localstorage.
  Otherwise, disconnects can cause desyncs and eventually errors.
 
  do stuff online ...
  disconect
  offline -> create scanner profile (db: scannerProfile updated locally)
  reconnect
  online -> move mosaic (db: layout updated -> points to scanner)
 
  Now, database points to non-existent scanner profile.
  Things will work perfectly in localstorage, until the user needs a full DB update again.
  Then everything breaks.
  */


  const params = buildLayoutParamsFromReducedState(state, specifiedParams, expressionPayload);
  const timestamp = +new Date();

  const uniqueId = _uniqueId();
  console.log(`[AUTOSAVE] Queueing persistLayout() ${uniqueId}:${timestamp}...`)


  logPersistLayoutInfo(params, reason);
  queueLogger.addRequest(timestamp, 'push', reason, params);

  persistDatabaseQueue.push(
    async () => {
      queueLogger.addRequestStep(timestamp, 'pre-fetch');

      let response = {};

      response = await persistLayoutDatabase(params, timestamp);
      // if (process.env.REACT_APP_USERS_LAMBDA_STAGE === 'prod') {
      // } else {
      //   console.info('[AUTOSAVE] SKIPPING DB SAVE IN DEVELOPMENT! localstorage allowed.')
      //   await PromiseHelper.sleep(400);
      // }
      queueLogger.addRequestStep(timestamp, 'post-fetch');

      return response;
    },
    {
      onQueueComplete: ({ processIndex, batchIndex, batchId, successes, error }) => {
        queueLogger.queueComplete(processIndex, batchIndex, batchId, successes, error);
        console.info(`[AUTOSAVE][persistDatabaseQueue()][${uniqueId}:${timestamp}] queue complete. Processed ${successes.length} items`)

        persistLayoutLocalStorage(state, getState, timestamp, reason);

        if (specifiedParams?.expressions === true) {
          // The globalExpressions reducer should, by now, have updated expressions.
          // TODO: do we really want to do it this way? We're diverging again. This is a bit of a mess.
          //  You MUST not do anything inside the reducer with expressions. Everything must come from actions...
          dispatch({ type: WRITE_EXPRESSIONS_LOCAL_STORAGE, writeLocalStorage: timestamp, payload: {} });
        }
      },
      onQueueFailure: ({ processIndex, batchIndex, batchId, successes, error }) => {
        queueLogger.queueError(processIndex, batchIndex, batchId, successes, error);

        Sentry.captureException(error);
        const payload = {
          ...createErrorNotification(
            'Failed to save changes. You may have lost internet connection. Please try refreshing the page.'
          )
        };
        dispatch({ type: 'GENERIC_ERROR__NO_TARGET', payload })
      }
    }
  );
};


/**
 * Stores a carbon copy of state into local storage. Its structured differently than our POST requests.
 *
 * @param {object} nextState
 * @param {callback} getState
 * @param {UnixSeconds} timestamp
 * @returns {boolean|Error}
 */
const persistLayoutLocalStorage = (nextState, getState, timestamp) => {
  try {
    const {
      [STORE_KEY]: prevState,
      ...rest
    } = getState();

    const userSub = rest?.account?.user?.userSub;

    if (!userSub) {
      console.error('persistLayoutLocalStorage - No userSub found, cannot save to localstorage.')
      return false;
    }

    const stateToSave = _cloneDeep({
      ...prevState,
      ...nextState,
      timestamp
    });

    const profileKeysToRemove = Object.values(PROFILE_CONFIG)
      .filter(values => values.nonLayoutState)
      .map(value => value.listKey);
    const dataKeysToRemove = Object.values(PROFILE_CONFIG)
      .filter(values => values?.dataKey)
      .map(value => value.dataKey);

    const keysToRemove = ['incrementer', 'isFetching', 'ui', 'initialized', ...dataKeysToRemove, ...profileKeysToRemove];
    keysToRemove.forEach(key => delete stateToSave[key]);

    const jsonData = JSON.stringify(stripAllPredefiendProfiles(stateToSave));

    requestIdleCallback(() => {
      localStorage.setItem(namespacedStorageKey(userSub), jsonData);
    })
    // console.log(`[AUTOSAVE] queue time: ${Math.round(+new Date() - stateToSave.timestamp)}ms`);
  } catch (err) {
    Sentry.captureException(err);
    console.error(err);
    return err;
  }
  return true;
};


/**
 * Sends the modified state to the DB in a request structure
 *
 * @param {object} params - data to send
 * @param {UnixSeconds} timestamp
 * @returns {any}
 */
const persistLayoutDatabase = async (params, timestamp) => {
  params.timestamp = timestamp;
  const { data } = await edgeUsersApi.post(`/user/layouts/toplist/${STORAGE_VERSION}`, params);
  if (!data?.status === 'success') {
    throw Error('Failed to persist to database, Axios did not error.');
  }
};


let autosaveCount = 0;
const logPersistLayoutInfo = (params, reason) => {
  if (!params || !Object.keys(params).length) {
    console.log(`[AUTOSAVE][${reason}] Empty, ignoring...`);
    return false;
  }
  const size = new TextEncoder().encode(JSON.stringify(params)).length;
  const kiloBytes = Math.round(size / 1024);
  console.log(`[AUTOSAVE][${kiloBytes}kB] ${reason}:${autosaveCount}`, params);
  autosaveCount++;
};



const buildDefaultParams = (state) => {
  const PROFILE_PARAMS = Object.values(PROFILE_CONFIG)
    .filter(value => !value.nonLayoutState)
    .map(value => value.listKey);

  return {
    'putLayouts': Object.keys(state.layouts),
    ...META_PARAMS.reduce((obj, key) => ({ ...obj, [key]: true }), {}),
    ...PROFILE_PARAMS.reduce((obj, key) => ({ ...obj, [key]: true }), {})
  };
}


/**
 * @summary Load the layout from database and localstorage
 * Attempts to load from localstorage first
 * Checks db layout timestamp (on user record)
 * If local doesn't exist, or local timestamp < db timestamp: try database
 * If local fails, and db fails (or db timestamp is empty), initialize empty
 *
 * IF initializes empty, save the default layout to the database. Easier to work with going forward,
 * if default layout changes.
 *
 * @param {number} lastDatabaseLayoutTimestamp - The timestamp of the last layout update in the database. Used to check if localstorage is fresh or stale.
 */
export const loadLayout = (
  lastDatabaseLayoutTimestamp,
  {
    planLevel = PLAN_LEVELS.PRO_PLUS, // Maybe a bad default?
    profilePermissions = {}
  } = {}
) => async (dispatch, getState) => {

  console.debug('[LOAD_LAYOUT] PARAMS: ', { lastDatabaseLayoutTimestamp, planLevel, profilePermissions });
  const {
    [STORE_KEY]: defaultState,
    ...rest
  } = _cloneDeep(getState());

  const userSub = rest?.account?.user?.userSub;

  const localState = fetchLayoutsLocalStorage(userSub);
  dispatch({ type: BEGIN__LOAD_STORAGE });

  const persistDefaultLayoutParams = buildDefaultParams(defaultState);

  // MUTABLE FUNCTIONS!
  const performStatePreInitializationMapping = (state) => {
    return _flow(
      checkScannerTickerExpirationList,
      changeFiltersAllowNullFieldLocation,
      addAllPredefinedProfiles,
      (s) => fixProfilePermissionsOnDowngrade(s, planLevel, profilePermissions)
    )(state);
  };

  console.log(`[LOAD_LAYOUT][] BEGIN... DB TIMESTAMP:${lastDatabaseLayoutTimestamp}, LOCAL TIMESTAMP:${localState?.timestamp}`);

  if (!localState) {
    if (!lastDatabaseLayoutTimestamp) {
      console.log('[LOAD_LAYOUT][EMPTY] NO LOCAL, NO TIMESTAMP.');
      dispatch(persistLayout(defaultState, persistDefaultLayoutParams, 'INITIALIZE_LAYOUT: NO LOCAL, NO TIMESTAMP'));
      return dispatch({ type: INITIALIZE_DEFAULT_LAYOUT });
    }
  } else if (localState instanceof Error) {
    console.error(localState);
    Sentry.captureException(localState);
    if (!lastDatabaseLayoutTimestamp) {
      console.log('[LOAD_LAYOUT][EMPTY] ERROR LOCAL, NO TIMESTAMP.');
      dispatch(persistLayout(defaultState, persistDefaultLayoutParams, 'INITIALIZE_LAYOUT: ERROR LOCAL, NO TIMESTAMP'));
      return dispatch({ type: INITIALIZE_DEFAULT_LAYOUT });
    }
  } else if (localState && localState?.timestamp) {
    if (!lastDatabaseLayoutTimestamp) {
      console.log('[LOAD_LAYOUT][LOCAL] LOCAL EXISTS, NO TIMESTAMP.');
      return dispatch({ type: SUCCESS__LOAD_STORAGE, payload: performStatePreInitializationMapping(localState) });
    }
    if (lastDatabaseLayoutTimestamp <= localState?.timestamp) {
      console.log('[LOAD_LAYOUT][LOCAL] LOCAL EXISTS, DB TIMESTAMP OOD.');
      return dispatch({ type: SUCCESS__LOAD_STORAGE, payload: performStatePreInitializationMapping(localState) });
    }
  }

  let dbState = await fetchLayoutsDataBase();

  if (dbState && dbState?.activeLayout) {
    // -- START FIX BAD PROFILES --
    // Fix users who currently have a bad profile in the DB.
    // This is not a long-term solution. AsyncQueue update should
    // already fix this going forward.
    // Once all users have valid profs, please delete this code.
    try {
      let params = {};
      const updates = validateProfile(dbState);

      if (updates.length) {

        updates.forEach(update => {
          let before = update?.get?.(dbState);
          update.update?.(dbState);
          params = update?.param?.(params);
          let after = update?.get?.(dbState);
          if (before || after) {
            update.log.before = before;
            update.log.after = after;
            update.log.success = after === update?.log?.shouldBe;
          }
        });

        if (process.env.REACT_APP_USERS_LAMBDA_STAGE !== 'prod') {
          console.info('[LOAD_LAYOUT] INVALID_LAYOUT_MODIFY', updates.map(u => u.log));
        }

        Sentry.withScope((scope) => {
          scope.addAttachment({
            filename: `invalid_layout_state_${+ new Date()}.json`,
            data: JSON.stringify({
              logs: updates.map(u => u.log),
              changedState: dbState
            }),
            contentType: 'application/json'
          })
          Sentry.captureException(new Error('Invalid layout state detected'));
        });

        dispatch(persistLayout(dbState, params, 'INVALID_LAYOUT_MODIFY'));
      }

    } catch (err) {
      console.error(err);
      Sentry.captureException(err);
    }

    // -- END FIX BAD PROFILES --

    console.log('[LOAD_LAYOUT][DB] DB EXISTS AND LOCAL DOES NOT / IS OOD');
    dispatch({ type: SUCCESS__LOAD_STORAGE, payload: performStatePreInitializationMapping(dbState) });
  } else if (dbState instanceof Error) {
    console.error(dbState);
    Sentry.captureException(dbState);
    if (localState && localState?.timestamp) {
      console.log('[LOAD_LAYOUT][LOCAL] LOCAL OUT OF DATE, BUT DB FAILED.');
      return dispatch({ type: SUCCESS__LOAD_STORAGE, payload: performStatePreInitializationMapping(localState) });
    } else {
      console.log('[LOAD_LAYOUT][EMPTY] DB ERROR AND LOCAL ERROR/EMPTY.');
      dispatch(persistLayout(defaultState, persistDefaultLayoutParams, 'INITIALIZE_LAYOUT: DB ERROR AND LOCAL ERROR/EMPTY'));
      return dispatch({ type: INITIALIZE_DEFAULT_LAYOUT });
    }
  } else {
    if (localState && localState?.timestamp) {
      console.log('[LOAD_LAYOUT][LOCAL] LOCAL OUT OF DATE, BUT DB EMPTY.');
      return dispatch({ type: SUCCESS__LOAD_STORAGE, payload: performStatePreInitializationMapping(localState) });
    } else {
      console.log('[LOAD_LAYOUT][EMPTY] DB EMPTY AND LOCAL ERROR/EMPTY.');
      dispatch(persistLayout(defaultState, persistDefaultLayoutParams, 'INITIALIZE_LAYOUT: DB EMPTY AND LOCAL ERROR/EMPTY'));
      return dispatch({ type: INITIALIZE_DEFAULT_LAYOUT });
    }
  }
};


const fetchLayoutsLocalStorage = (userSub) => {
  if (!userSub) return false;

  try {
    return JSON.parse(localStorage.getItem(namespacedStorageKey(userSub)));
  } catch (err) {
    return err;
  }
};



const fetchLayoutsDataBase = async () => {
  let url = `/user/layouts/toplist/${STORAGE_VERSION}`

  try {
    const { data } = await edgeUsersApi.get(url);
    return data;
  } catch (err) {
    return err;
  }
};

